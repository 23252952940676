@import '~antd/dist/antd.css';
@import './styles/FileTable.scss';

div#root {
  height: 100%;
}
.ant-layout {
  display: flex;
  height: 100%;
}
.ant-layout-header {
  display: grid;
  grid-template-columns: auto 160px;
  grid-template-rows: 1fr;
  background:  white;
  .speed-container {
    margin: 0;
    color: #1890ff;
    text-align: center;
  }
  .btn__logout {
    margin: auto 0;
  }
}
.ant-layout-content {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  align-items: center;
  justify-content: flex-start;
}
.login-container {
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 300px;

  button {
    width: 100%;
  }
}
.logo{
  width: 120px;
  height: 39px;
//  / background: rgba(255, 255, 255, 0.3);
  //margin: -50px 24px 12px 0;
  position: absolute;
  top: 12px;
  img{
    float: left;
    width: 120px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
