.filetable-container {
  min-width: 80%;
  .ant-breadcrumb {
    display: flex;
    button {
      padding: 0;
    }
    > span:last-child {
      button {
        font-weight: bold;
      }
    }
    .anticon-down {
      cursor: pointer;
    }
  }
  .cell__folder {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #1890ff;
      font-weight: bold;
    }
    .anticon {
      margin-right: 8px;
    }
  }

  .cell__download {
    text-align: center;
  }

  .filetable-actions {
    display: flex;
    justify-content: space-between;
  }
}